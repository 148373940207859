<template>
   <ObiText class="obi-notification">
      <ObiText class="obi-notification-header">
         <ObiTitle class="mb-2" :title="$t('notification_title')" />
      </ObiText>
      <ObiText class="obi-notification-content">
         <ObiText
            class="obi-notification--item"
            v-for="notification in notifications"
            :key="notification.id"
            :class="[notification.data.type, { unread: !notification.is_read }]"
         >
            <ObiText v-if="notification.data.type" v-html="notification.data.message" :color="notification.data.type" />
            <ObiText v-else v-html="notification.data.message" />

            <ObiText
               class="d-flex justify-content-between align-items-center mt-4"
               v-if="notification.data.name === 'InvitationNotification'"
            >
               <ObiText>
                  <ObiButton
                     size="md"
                     color="danger"
                     :loading="isAcceptInProgress"
                     :text="$t('actions.accept')"
                     @click="acceptInvite(notification)"
                  />
                  <ObiButton
                     class="ms-2"
                     size="md"
                     color="warning"
                     :loading="isDenyInProgress"
                     :text="$t('actions.deny')"
                     @click="denyInvite(notification)"
                  />
               </ObiText>
            </ObiText>

            <timeago
               class="text-primary mt-2"
               :datetime="notification.created_at"
               locale="tr"
               :auto-update="60"
            ></timeago>
         </ObiText>
      </ObiText>
   </ObiText>
</template>

<script>
import ErrorHandler from '@/libs/error-handler';

import AuthApi from '@/api/AuthApi';

export default {
   name: 'ObiCol',
   i18n: {
      messages: {
         tr: {
            notification_title: 'Bildirimlerim',
            actions: {
               accept: 'Kabul Et',
               deny: 'Reddet',
            },
         },
         en: {
            notification_title: 'Notifications',
            actions: {
               accept: 'Accept',
               deny: 'Decline',
            },
         },
      },
   },
   props: {
      notifications: {
         type: Array,
      },
      unread: {
         type: Number,
         default: 0,
      },
   },
   computed: {},
   data() {
      return {
         isAcceptInProgress: false,
         isDenyInProgress: false,
      };
   },
   methods: {
      async acceptInvite(notification) {
         this.isAcceptInProgress = true;

         try {
            await AuthApi.acceptInvite(notification.data.token, { notificationId: notification.id });
         } catch (error) {
            ErrorHandler.handle(error);
         } finally {
            this.isAcceptInProgress = false;
            this.$store.dispatch('auth/me');
         }
      },
      async denyInvite(notification) {
         this.isDenyInProgress = true;

         try {
            await AuthApi.denyInvite(notification.data.token, { notificationId: notification.id });
         } catch (error) {
            ErrorHandler.handle(error);
         } finally {
            this.isDenyInProgress = false;
            this.$store.dispatch('auth/me');
         }
      },
   },
};
</script>
<style lang="scss" scoped>
.obi-notification {
   padding: 0;
   border-radius: 10px;
   box-shadow: 0 5px 20px -3px rgb(0 0 0 / 16%);
   border: 0;
   max-width: 400px;

   .obi-notification-header {
      padding: 10px 20px;
      border-bottom: 1px solid #ddd;
   }
   .obi-notification-content {
      height: 550px;
      overflow: auto;

      .obi-notification--item {
         display: flex;
         flex-flow: column;
         justify-content: space-between;
         padding: 20px 25px;
         border-bottom: 1px solid #ddd;
         &.unread {
            background: #f4f8fa;
            border-left: 2px solid #007bff;
         }

         &.danger {
            border-right: 4px solid #ff8e8e;
         }
         &.success {
            border-right: 4px solid #46d68c;
         }
         &.info {
            border-right: 4px solid #777d96;
         }
         &.warning {
            border-right: 4px solid #f3b007;
         }
      }
   }
}
</style>
